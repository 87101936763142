/* global google */
// /* eslint-disable import/first */
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';
import './components/select/react-select.scss';
import './index.scss';
import './utils/extentions';
import jquery from 'jquery';
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route, browserHistory, IndexRedirect } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from 'react-redux-i18n';
import App from './containers/app/App';
import configureStore from './store/configureStore';
import { translations } from './constants/languages/languages';
import handleLoadSettingServer from './utils/loadServerSetting';
const Affiliation = lazy(() => import('./containers/reports/affiliation'));
const BookingDetails = lazy(() => import('./containers/reports/booking-details'));
const BookingLogs = lazy(() => import('./containers/reports/booking-logs'));
const BookingSummary = lazy(() => import('./containers/reports/booking-summary'));
const CarActivity = lazy(() => import('./containers/reports/car-activity'));
const ReportCorporate = lazy(() => import('./containers/reports/corporate'));
const DailyCar = lazy(() => import('./containers/reports/daily-car'));
const DailyDriver = lazy(() => import('./containers/reports/daily-driver'));
const DailyDriverCar = lazy(() => import('./containers/reports/daily-driver-car'));
const DocumentExpiry = lazy(() => import('./containers/reports/document-expiry'));
const CreditWallet = lazy(() => import('./containers/reports/credit-wallet'));
const CashWallet = lazy(() => import('./containers/reports/cash-wallet'));
const DriverLoginStatus = lazy(() => import('./containers/reports/driver-login-status'));
const Financial = lazy(() => import('./containers/reports/financial'));
const FinancialCompany = lazy(() => import('./containers/reports/financial-company'));
const FinancialProfit = lazy(() => import('./containers/reports/financial-profit'));
const FinancialDriver = lazy(() => import('./containers/reports/financial-driver'));
const IncidentCancellation = lazy(() => import('./containers/reports/incident-cancellation'));
const mDispatcher = lazy(() => import('./containers/reports/mDispatcher'));
const OperatorLogs = lazy(() => import('./containers/reports/operator-logs'));
const PaidToDriver = lazy(() => import('./containers/reports/paid-to-driver'));
const PrepaidTopUp = lazy(() => import('./containers/reports/prepaid'));
const Promotion = lazy(() => import('./containers/reports/promotion'));
const Rating = lazy(() => import('./containers/reports/rating'));
const AcceptCancelRate = lazy(() => import('./containers/reports/accept-cancel-rate'));
const Report = lazy(() => import('./containers/reports/Report'));
const RevenueDriver = lazy(() => import('./containers/reports/revenue-driver'));
const RevenueFleet = lazy(() => import('./containers/reports/revenue-fleet'));
const RevenueMonthly = lazy(() => import('./containers/reports/revenue-monthly'));
const SettlementHistory = lazy(() => import('./containers/reports/settlement-history'));
const TotalAdjustment = lazy(() => import('./containers/reports/total-adjustment'));
const ReferralDetail = lazy(() => import('./containers/reports/driver-referral-detail'));
const DriverRefersPax = lazy(() => import('./containers/reports/driver-refers-pax'));
const PassengerRefersPassengers = lazy(() => import('./containers/reports/passenger-refers-passengers'));
const DriverWithdrawal = lazy(() => import('./containers/reports/driver-withdrawal'));
const PenaltyCompensation = lazy(() => import('./containers/reports/penalty-compensation'));
const IncompletePayment = lazy(() => import('./containers/reports/incomplete-payment'));
const PassengerWallet = lazy(() => import('./containers/reports/passenger-wallet'));
const ReferralPassengers = lazy(() => import('./containers/reports/referral-passengers'));
const PassengerRefersPassengersDetails = lazy(() => import('./containers/reports/pax-referral-passengers-details'));
const Refund = lazy(() => import('./containers/reports/refund'));
const PayToDriver = lazy(() => import('./containers/reports/PayToDriver'));
const PayoutHistory = lazy(() => import('./containers/reports/PayoutHistory'));
const ReportVoucher = lazy(() => import('./containers/reports/voucher'));
const FirstWillWin = lazy(() => import('./containers/reports/quest'));
const DriverRefersReferrer = lazy(() => import('./containers/reports/driver-refers-referrer'));
const DriverRefersReferee = lazy(() => import('./containers/reports/driver-refers-referee'));
const ReportMerchant = lazy(() => import('./containers/reports/merchant'));
const Airline = lazy(() => import('./containers/reports/airline'));
const PayToMerchant = lazy(() => import('./containers/reports/PayToMerchant'));
const PayoutHistoryMerchant = lazy(() => import('./containers/reports/PayoutHistoryMerchant'));
const MerchantCashTransactionHistory = lazy(() => import('./containers/reports/cc-merchant-cash-transaction-history'));
const MerchantCreditTransactionHistory = lazy(() => import('./containers/reports/cc-merchant-credit-transaction-history'));
const DeleteAccountRequest = lazy(() => import('./containers/reports/delete-account-request'));
const PassengerRating = lazy(() => import('./containers/reports/passenger-rating'));
const Export = lazy(() => import('./containers/reports/export'));
const AffiliationPayoutHistory = lazy(() => import('./containers/reports/affiliation-payout-history'));
const AffiliationPayoutHistoryDetails = lazy(() => import('./containers/reports/affiliation-payout-history-details'));
const CompanySettlement = lazy(() => import('./containers/reports/company-settlement'));
const CompanySettlementDetails = lazy(() => import('./containers/reports/company-settlement-details'));
const ThirdPartyBooking = lazy(() => import('./containers/reports/third-party-booking'));
const Holidaytaxis = lazy(() => import('./containers/reports/holidaytaxis'));
const Karhoo = lazy(() => import('./containers/reports/karhoo'));
const Hoppa = lazy(() => import('./containers/reports/hoppa'));
const StreetSharing = lazy(() => import('./containers/settings/rate/sharing/sharing'));
const AppBanner = lazy(() => import('./containers/settings/appBanner'));
const Campaign =  lazy(() => import('./containers/settings/promotion/campaign'));
const AppBannerPegasus =  lazy(() => import('./containers/settings/appBannerPegasus'));
const CarColor =  lazy(() => import('./containers/settings/car/CarColor'));
const ErrorPage = lazy(() => import('./containers/misc/ErrorPage'));
const Map = lazy(() => import('./containers/map'));
const Settings = lazy(() => import('./containers/settings/Settings'));
const Cue = lazy(() => import('./containers/cue/Cue'));
const Customer = lazy(() => import('./containers/customers/Customer'));
const Driver = lazy(() => import('./containers/drivers/Driver'));
const Message = lazy(() => import('./components/message/Message'));
const FleetInfoSetting = lazy(() => import('./containers/settings/fleetInfo'));
const StripeConnect = lazy(() => import('./containers/settings/stripeConnect/StripeConnect'));
const GeneralSetting = lazy(() => import('./containers/settings/general'));
const Login = lazy(() => import('./containers/login/Login'));
const AddEditIntercityRouteModal = lazy(() => import('./containers/settings/rate/intercity/IntercityRoute/AddEditIntercityRouteModal'));
const User = lazy(() => import('./containers/settings/user/user'));
const FlatZone = lazy(() => import('./containers/settings/zone/flatZone'));
const PWA = lazy(() => import('./containers/settings/pwa'));
const IntercityZone = lazy(() => import('./containers/settings/zone/intercityZone'));
const Company = lazy(() => import('./containers/settings/compnay/company'));
const DispatchSetting = lazy(() => import('./containers/settings/DispatchSetting'));
const VoiceSMSSettings = lazy(() => import('./containers/settings/voicesms'));
const RegularSetting = lazy(() => import('./containers/settings/rate/regular/regular'));

const RegularRateSetting = lazy(() => import('./containers/settings/rateRefactor/regular'));
const RegularRateSettingCreate = lazy(() => import('./containers/settings/rateRefactor/regular/CreateRegular'));
const RegularRateSettingDetail = lazy(() => import('./containers/settings/rateRefactor/regular/DetailRegular'));

const HourlyRateSetting = lazy(() => import('./containers/settings/rateRefactor/hourly'));
const HourlyRateSettingCreate = lazy(() => import('./containers/settings/rateRefactor/hourly/CreateHourly'));
const HourlyRateSettingDetail = lazy(() => import('./containers/settings/rateRefactor/hourly/DetailHourly'));
const HourlyRateSpecialSettingCreate = lazy(() => import('./containers/settings/rateRefactor/hourly/CreateHourlySpecial'));

const FlatRateSetting = lazy(() => import('./containers/settings/rateRefactor/flat'));
const FlatRateSettingCreate = lazy(() => import('./containers/settings/rateRefactor/flat/CreateFlat'));
const FlatRateSettingDetail = lazy(() => import('./containers/settings/rateRefactor/flat/DetailFlat'));
const FlatRateSpecialSettingCreate = lazy(() => import('./containers/settings/rateRefactor/flat/CreateFlatSpecial'));

const SurchargePolicy = lazy(() => import('./containers/settings/surchargePolicy'));
const SurchargePolicyCreate = lazy(() => import('./containers/settings/surchargePolicy/CreateSurchargePolicy'));

const RateTemplate = lazy(() => import('./containers/settings/rateTemplate'));
const RateTemplateForm = lazy(() => import('./containers/settings/rateTemplate/RateTemplateForm'));

const PenaltyPolicyCustomer = lazy(() => import('./containers/settings/penaltyPolicy/PenaltyPolicyCustomer'));
const PenaltyPolicySupplier = lazy(() => import('./containers/settings/penaltyPolicy/PenaltyPolicySupplier'));

const SupplierPlan = lazy(() => import('./containers/settings/supplierPlan'));

const Operation = lazy(() => import('./containers/settings/operation/operation'));
const HourlySetting = lazy(() => import('./containers/settings/rate/hourly/hourly'));
const FlatSetting = lazy(() => import('./containers/settings/rate/flat/flat'));
const IntercityRate = lazy(() => import('./containers/settings/rate/intercity'));
const DeliveryRate = lazy(() => import('./containers/settings/rate/delivery/delivery'));
const ForgotPassword = lazy(() => import('./containers/login/forgotPassword'));
const ResetPassword = lazy(() => import('./containers/login/resetPassword'));
const CarMGMT = lazy(() => import('./containers/settings/car/carMgmt'));
const MakeModel = lazy(() => import('./containers/settings/car/makeModel'));
const CarType = lazy(() => import('./containers/settings/car/carType'));
const ChangePassword = lazy(() => import('./containers/userSettings/changePassword'));
const UserProfile = lazy(() => import('./containers/userSettings/userProfile'));
const ShiftSettings = lazy(() => import('./containers/settings/shift/shiftSetting'));
const ShiftTemplate = lazy(() => import('./containers/settings/shift/shiftTemplate'));
const PromotionCode = lazy(() => import('./containers/settings/promotion/promotionCode'));
const Quest = lazy(() => import('./containers/settings/promotion/Quest'));
const Voucher = lazy(() => import('./containers/settings/promotion/Voucher'));
const City = lazy(() => import('./containers/settings/city/city'));
const Booking3rdIntegration = lazy(() => import('./containers/settings/3rdPartyIntergration/booking'));
const HolidayTaxis = lazy(() => import('./containers/settings/3rdPartyIntergration/holidaytaxis'));
const KarhooSetting = lazy(() => import('./containers/settings/3rdPartyIntergration/karhoo'));
const HoppaSetting = lazy(() => import('./containers/settings/3rdPartyIntergration/hoppa'));
const MDispatcherType = lazy(() => import('./containers/settings/partner-type/mDispatcherType'));
const FlightIntegration = lazy(() => import('./containers/settings/flightIntegration'));
const APIManagement = lazy(() => import('./containers/settings/APIManagement'));
const SMSIntegration = lazy(() => import('./containers/settings/smsIntergration'));
const InvoiceSetting = lazy(() => import('./containers/settings/invoiceSetting'));
const Permission = lazy(() => import('./containers/settings/permission/permission'));
const ThirdParty = lazy(() => import('./containers/settings/thirdParty/thirdParty'));
const Category = lazy(() => import('./containers/settings/thirdParty/category'));
const DynamicSurcharge = lazy(() => import('./containers/settings/dynamicSurcharge/containers/DynamicSurcharge'));
const DynamicSurchargeDetail = lazy(() => import('./containers/settings/dynamicSurcharge/containers/Surcharge'));
const MDispatcher = lazy(() => import('./containers/mDispatcher/mDispatcher'));
const Corporate = lazy(() => import('./containers/corporate/Corporate'));
const CorporateAdd = lazy(() => import('./containers/corporate/Add'));
const CorporateEdit = lazy(() => import('./containers/corporate/Edit'));
const PointConfig = lazy(() => import('./containers/settings/pointConfig'));
const DriverToPax = lazy(() => import('./containers/settings/referral/driverToPax'));
const DynamicFare = lazy(() => import('./containers/settings/dynamicFare/containers/DynamicFare'));
const DynamicFareDetail = lazy(() => import('./containers/settings/dynamicFare/containers/Fare'));
const ImportBookings = lazy(() => import('./containers/importBookings/ImportBookings'));
const Merchant = lazy(() => import('./containers/merchant'));
const Invoice = lazy(() => import('./containers/Invoice'));
const Notifications = lazy(() => import('./containers/notificationDetails/notifications/Notifications'));
const PassengerRefersPax = lazy(() => import('./containers/settings/referral/paxRefersPax'));
const DriverReferDriver = lazy(() => import('./containers/settings/referral/DriverReferDriver'));
const PaymentInfo = lazy(() => import('./containers/settings/paymentInfo/PaymentInfo'));
const QueuingArea = lazy(() => import('./containers/queuingArea/queuingArea'));
const Reservation = lazy(() => import('./containers/reservation/Reservation'));
const SignUpNotifications = lazy(() => import('./containers/notificationDetails/signUpNotifications'));
const SmartData = lazy(() => import('./containers/smartData'));
const SOS = lazy(() => import('./containers/notificationDetails/sos/sos'));
const Trip = lazy(() => import('./containers/trips/Trip'));
const WithdrawalRequests = lazy(() => import('./containers/withdrawal/Withdrawal'));
const DriverNote = lazy(() => import('./containers/settings/driver-note/driverNote'));
const Analytics = lazy(() => import('./containers/analytics/analytics'));
const NewBookingFromUrl = lazy(() => import('./containers/newbooking/NewBookingFromUrl'));
const BookingDetailFromUrl = lazy(() => import('./containers/BookingDetailFromUrl'));
const Version = lazy(() => import('./containers/version'));
const jobsTab = lazy(() => import('./containers/settings/driverApp/jobsTab'));
const EmailConfig = lazy(() => import('./containers/settings/emailConfig/emailConfig'));
const DriverFields = lazy(() => import('./containers/settings/driverFields/driverFields'));
const DriverDocument = lazy(() => import('./containers/settings/driverDocument/driverDocument'));
const Social = lazy(() => import('./containers/settings/social/social'));
const VehicleContainer = lazy(() => import('./containers/vehicle/index'));
const CreateVehicle = lazy(() => import('./containers/vehicle/create'));
const DetailVehicle = lazy(() => import('./containers/vehicle/detail'));
const CreateDriver = lazy(() => import('./containers/drivers/create'));
const DetailDriver = lazy(() => import('./containers/drivers/detail'));
const createSupplier = lazy(() => import('./containers/supplier/create'));
const Supplier = lazy(() => import('./containers/supplier'));
const SupplierDetail = lazy(() => import('./containers/supplier/SupplierDetail'));
const SupplierComponent = lazy(() => import('./components/supplierComponent/bookingDetail'));

const store = configureStore();
export { store }; // Export store to use in other file

const history = syncHistoryWithStore(browserHistory, store);
syncTranslationWithStore(store);
store.dispatch(loadTranslations({ 'en-US': translations['en-US'] }));
store.dispatch(setLocale('en-US'));
handleLoadSettingServer();

ReactDOM.render(
  <Suspense fallback={<div></div>}>
    <Provider store={store}>
      <Router history={history}>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password/:token" component={ResetPassword} />
        <Route path="/version" component={Version} />
        <Route path="/" component={App}>
          {/* <IndexRedirect to="/map" /> */}
          <Route path="/supplier-booking-detail/:bookId" component={SupplierComponent} />
          <Route path="/cue" component={Cue} />
          <Route path="/newbooking" component={NewBookingFromUrl} />
          <Route path="/booking-detail" component={BookingDetailFromUrl} />
          <Route path="/import_bookings" component={ImportBookings} />
          <Route
            path="/import SMSIntegration from './containers/settings/smsIntergration/index';
settings"
            component={Settings}
          >
            <Route path="/settings/Fleet_info" component={FleetInfoSetting} />
            <Route path="/settings/Stripe_connect" component={StripeConnect} />
            <Route path="/settings/General" component={GeneralSetting} />
            <Route path="/settings/User" component={User} />
            <Route path="/settings/Zone/FlatZone" component={FlatZone} />
            <Route path="/settings/PWA" component={PWA} />
            <Route
              path="/settings/Zone/IntercityZone"
              component={IntercityZone}
            />
            <Route path="/settings/Company" component={Company} />
            <Route path="/settings/Dispatch" component={DispatchSetting} />
            <Route path="/settings/Voice_SMS" component={VoiceSMSSettings} />
            <Route path="/settings/rate/regular" component={RegularSetting} />

            <Route path="/settings/rate/regular-rate" component={RegularRateSetting} />
            <Route path="/settings/rate/regular-rate/create" component={RegularRateSettingCreate} />
            <Route path="/settings/rate/regular-rate/detail/:regularId/:tabActive" component={RegularRateSettingDetail} />

            <Route path="/settings/rate/hourly-rate" component={HourlyRateSetting} />
            <Route path="/settings/rate/hourly-rate/create" component={HourlyRateSettingCreate} />
            <Route path="/settings/rate/hourly-rate/detail/:rateId/:tabActive" component={HourlyRateSettingDetail} />
            <Route path="/settings/rate/hourly-rate/detail/:rateId/:tabActive/:tabAction" component={HourlyRateSpecialSettingCreate} />

            <Route path="/settings/rate/flat-rate" component={FlatRateSetting} />
            <Route path="/settings/rate/flat-rate/create" component={FlatRateSettingCreate} />
            <Route path="/settings/rate/flat-rate/detail/:rateId/:tabActive" component={FlatRateSettingDetail} />
            <Route path="/settings/rate/flat-rate/detail/:rateId/:tabActive/:tabAction" component={FlatRateSpecialSettingCreate} />

            <Route path="/settings/Rate/Flat" component={FlatSetting} />
            <Route path="/settings/rate/intercity" component={IntercityRate} />
            <Route
              path="/settings/rate/intercity/route"
              component={AddEditIntercityRouteModal}
            />
            <Route path="/settings/Rate/Delivery" component={DeliveryRate} />
            <Route path="/settings/Rate/Hourly" component={HourlySetting} />
            <Route
              path="/settings/rate/street_sharing"
              component={StreetSharing}
            />
            <Route path="/settings/Car/Car_mgmt" component={CarMGMT} />
            <Route
              path="/settings/Car/Car_mgmt/:action/:id"
              component={CarMGMT}
            />
            <Route path="/settings/Car/Car_Make" component={MakeModel} />
            <Route path="/settings/Car/Car_Color" component={CarColor} />
            <Route path="/settings/Car/Car_type" component={CarType} />
            <Route
              path="/settings/Shift/Shift_settings"
              component={ShiftSettings}
            />
            <Route
              path="/settings/Shift/Shift_template"
              component={ShiftTemplate}
            />
            <Route path="/settings/Operation" component={Operation} />
            <Route path="/settings/Driver_note" component={DriverNote} />
            <Route path="/settings/Promotion/Campaign" component={Campaign} />
            <Route
              path="/settings/Promotion/Promotion_code"
              component={PromotionCode}
            />
            <Route path="/settings/Point_config" component={PointConfig} />
            <Route path="/settings/Voucher_code" component={Voucher} />
            <Route path="/settings/Quest" component={Quest} />
            <Route path="/settings/City" component={City} />
            <Route path="/settings/Partner_type" component={MDispatcherType} />
            <Route path="/settings/Permission" component={Permission} />
            <Route path="/settings/Driver_app" component={jobsTab} />
            <Route
              path="/settings/Flight_integration"
              component={FlightIntegration}
            />
            <Route path="/settings/API_management" component={APIManagement} />
            <Route
              path="/settings/SMS_integration"
              component={SMSIntegration}
            />
            <Route
              path="/settings/Invoice_Setting"
              component={InvoiceSetting}
            />
            <Route
              path="/settings/third_party_integration/booking"
              component={Booking3rdIntegration}
            />
            <Route
              path="/settings/third_party_integration/holidaytaxis"
              component={HolidayTaxis}
            />
            <Route
              path="/settings/third_party_integration/karhoo"
              component={KarhooSetting}
            />
            <Route
              path="/settings/third_party_integration/hoppa"
              component={HoppaSetting}
            />
            <Route path="/settings/3rd_party_location" component={ThirdParty} />
            <Route
              path="/settings/3rd_party_location/category"
              component={Category}
            />
            {/* <Route path="/settings/Queuing_area" component={QueuingArea} /> */}
            <Route path="/settings/App_banner" component={AppBanner} />
            <Route
              path="/settings/App_banner_pegasus"
              component={AppBannerPegasus}
            />
            <Route
              path="/settings/Referral/Driver_refers_customer"
              component={DriverToPax}
            />
            <Route
              path="/settings/Referral/Customer_refers_customer"
              component={PassengerRefersPax}
            />
            <Route
              path="/settings/Referral/Driver_refer_driver"
              component={DriverReferDriver}
            />
            <Route path="/settings/payment_info" component={PaymentInfo} />
            <Route
              path="/settings/Dynamic_surcharge"
              component={DynamicSurcharge}
            />
            <Route
              path="/settings/Dynamic_surcharge/:zoneId"
              component={DynamicSurchargeDetail}
            />
            <Route path="/settings/Dynamic_fare" component={DynamicFare} />

            <Route path="/settings/rate_template" component={RateTemplate} />
            <Route path="/settings/rate_template/create" component={RateTemplateForm} />
            <Route path="/settings/rate_template/detail/:templateId" component={RateTemplateForm} />


            <Route path="/settings/surcharge_policy" component={SurchargePolicy} />
            <Route path="/settings/surcharge_policy/create" component={SurchargePolicyCreate} />
            <Route path="/settings/surcharge_policy/detail/:surchargeId" component={SurchargePolicyCreate} />

            <Route path="/settings/supplier_plan" component={SupplierPlan} />
            
            <Route path="/settings/penalty_policy_supplier" component={PenaltyPolicySupplier} />
            <Route path="/settings/penalty_policy_customer" component={PenaltyPolicyCustomer} />

            <Route
              path="/settings/Dynamic_fare/:zoneId"
              component={DynamicFareDetail}
            />
            <Route path="/settings/email_config" component={EmailConfig} />
            <Route
              path="/settings/Driver_document"
              component={DriverDocument}
            />
            <Route path="/settings/Driver_fields" component={DriverFields} />
            <Route path="/settings/social" component={Social} />
            <Route path="/settings/*" component={ErrorPage} />
          </Route>
          <Route path="/analytics" component={Analytics} />
          <Route path="/smartdata" component={SmartData} />
          <Route path="/map" component={Map} />
          <Route path="/customer" component={Customer} />
          <Route path="/customer/add" component={Customer} />
          <Route path="/customer/edit/:customerId" component={Customer} />
          <Route path="/customer/view/:customerId" component={Customer} />
          <Route path="/customer/message" component={Message} />
          <Route path="/customer/message/add" component={Message} />
          <Route
            path="/customer/message/detail/:messageId"
            component={Message}
          />
          <Route path="/driver" component={Driver} />
          <Route path="/driver/message" component={Message} />
          <Route path="/driver/message/add" component={Message} />
          <Route path="/driver/message/detail/:messageId" component={Message} />
          <Route path="/corporate" component={Corporate} />
          <Route path="/corporate/add" component={CorporateAdd} />
          <Route path="/driver/add" component={CreateDriver} />
          <Route path="/driver/view/:driverId/:tabActive" component={DetailDriver} />
          <Route path="/supplier/add" component={createSupplier} />
          <Route
            path="/corporate/edit/:corporateId"
            component={CorporateEdit}
          />
          <Route
            path="/corporate/view/:corporateId"
            component={CorporateEdit}
          />
          <Route path="/supplier" component={Supplier} />
          <Route path="/supplier/detail/:supplierId/:tabActive" component={SupplierDetail} />
          <Route path="/vehicle" component={VehicleContainer} />
          <Route path="/vehicle/add" component={CreateVehicle} />
          <Route path="/vehicle/:vehicleId" component={DetailVehicle} />
          <Route path="/mDispatcher" component={MDispatcher} />
          <Route path="/withdrawal_requests" component={WithdrawalRequests} />
          <Route path="/Queuingarea" component={QueuingArea} />
          <Route path="/Trip" component={Trip} />
          <Route path="/Merchant" component={Merchant} />
          <Route path="/Invoice" component={Invoice} />
          <Route path="/merchant/message" component={Message} />
          <Route path="/merchant/message/add" component={Message} />
          <Route
            path="/merchant/message/detail/:messageId"
            component={Message}
          />
          <Route path="/Payout/pay_to_driver" component={PayToDriver} />
          <Route
            path="/Payout/payout_history_for_driver"
            component={PayoutHistory}
          />
          <Route path="/Payout/pay_to_merchant" component={PayToMerchant} />
          <Route
            path="/Payout/payout_history_for_merchant"
            component={PayoutHistoryMerchant}
          />
          <Route path="/settlement/pay_to_driver" component={PaidToDriver} />
          <Route
            path="/settlement/settlement_history"
            component={SettlementHistory}
          />
          <Route path="/calendar" component={Reservation} />
          <Route path="/reports" component={Report}>
            <Route path="/reports/Booking_details" component={BookingDetails} />
            <Route path="/reports/3rdBooking" component={ThirdPartyBooking} />
            <Route path="/reports/holidaytaxis" component={Holidaytaxis} />
            <Route path="/reports/karhoo" component={Karhoo} />
            <Route path="/reports/hoppa" component={Hoppa} />
            <Route path="/reports/financial" component={Financial} />
            <Route
              path="/reports/financial/driver"
              component={FinancialDriver}
            />
            <Route
              path="/reports/financial/company"
              component={FinancialCompany}
            />
            <Route
              path="/reports/financial/profit"
              component={FinancialProfit}
            />
            <Route path="/reports/Car_activity" component={CarActivity} />
            <Route path="/reports/affiliation" component={Affiliation} />
            <Route
              path="/reports/affiliation/bookings"
              component={Affiliation}
            />
            <Route path="/reports/prepaid" component={PrepaidTopUp} />
            <Route path="/reports/operator_logs" component={OperatorLogs} />
            <Route path="/reports/corporate" component={ReportCorporate} />
            <Route path="/reports/airline" component={Airline} />
            <Route path="/reports/booking_logs" component={BookingLogs} />
            <Route path="/reports/promotion" component={Promotion} />
            <Route
              path="/reports/company_settlement"
              component={CompanySettlement}
            />
            <Route
              path="/reports/company_settlement/details"
              component={CompanySettlementDetails}
            />
            <Route path="/reports/rating" component={Rating} />
            <Route
              path="/reports/rating/passenger"
              component={PassengerRating}
            />
            <Route
              path="/reports/acceptcancel_rate"
              component={AcceptCancelRate}
            />
            <Route path="/reports/mDispatcher" component={mDispatcher} />
            <Route
              path="/reports/Total_adjustment"
              component={TotalAdjustment}
            />
            <Route
              path="/reports/Incident_Cancellation"
              component={IncidentCancellation}
            />
            <Route path="/reports/revenue" component={RevenueFleet} />
            <Route path="/reports/revenue/fleet" component={RevenueFleet} />
            <Route path="/reports/revenue/driver" component={RevenueDriver} />
            <Route path="/reports/revenue/monthly" component={RevenueMonthly} />
            <Route path="/reports/Daily" component={DailyDriver} />
            <Route path="/reports/Daily/Daily_Driver" component={DailyDriver} />
            <Route path="/reports/Daily/Daily_Car" component={DailyCar} />
            <Route
              path="/reports/Daily/Daily_Driver_Car"
              component={DailyDriverCar}
            />
            <Route
              path="/reports/Driver_login_status"
              component={DriverLoginStatus}
            />
            <Route path="/reports/Booking_summary" component={BookingSummary} />
            <Route path="/reports/Driver_wallet" component={CreditWallet} />
            <Route
              path="/reports/Driver_wallet/Credit_Wallet"
              component={CreditWallet}
            />
            <Route
              path="/reports/Driver_wallet/Cash_Wallet"
              component={CashWallet}
            />
            <Route
              path="/reports/Passenger_wallet"
              component={PassengerWallet}
            />
            <Route path="/reports/Document_expiry" component={DocumentExpiry} />
            <Route
              path="/reports/Driver_Referral_history"
              component={DriverRefersPax}
            />
            <Route
              path="/reports/financial/referral-details"
              component={ReferralDetail}
            />
            <Route
              path="/reports/Driver_Referral_history/Driver_refers_Pax"
              component={DriverRefersPax}
            />
            <Route
              path="/reports/Driver_Referral_history/Driver_refers_Pax/pax"
              component={ReferralPassengers}
            />
            <Route
              path="/reports/Driver_Referral_history/customers"
              component={ReferralPassengers}
            />
            <Route
              path="/reports/Driver_Referral_history/bookings"
              component={ReferralDetail}
            />
            <Route
              path="/reports/Passenger_refers_Passengers"
              component={PassengerRefersPassengers}
            />
            <Route
              path="/reports/Passenger_refers_Passengers/pax"
              component={PassengerRefersPassengersDetails}
            />
            <Route
              path="/reports/Driver_refers_drivers/Referrer"
              component={DriverRefersReferrer}
            />
            <Route
              path="/reports/Driver_refers_drivers/Referee"
              component={DriverRefersReferee}
            />
            <Route
              path="/reports/Driver_withdrawal"
              component={DriverWithdrawal}
            />
            <Route
              path="/reports/affiliation/penalty_compensation"
              component={PenaltyCompensation}
            />
            <Route
              path="/reports/affiliation/payout_history"
              component={AffiliationPayoutHistory}
            />
            <Route
              path="/reports/affiliation/payout_history/details"
              component={AffiliationPayoutHistoryDetails}
            />
            <Route
              path="/reports/incomplete_payment"
              component={IncompletePayment}
            />
            <Route path="/reports/refund" component={Refund} />
            <Route
              path="/reports/deletion_request"
              component={DeleteAccountRequest}
            />
            <Route path="/reports/redeemed" component={ReportVoucher} />
            <Route path="/reports/export" component={Export} />
            <Route path="/reports/quest" component={FirstWillWin} />
            <Route
              path="/reports/merchant/financial"
              component={ReportMerchant}
            />
            <Route
              path="/reports/merchant/cash"
              component={MerchantCashTransactionHistory}
            />
            <Route
              path="/reports/merchant/credit"
              component={MerchantCreditTransactionHistory}
            />
            <Route path="/notifications" component={Notifications} />
            <Route path="/sos" component={SOS} />
            <Route
              path="/signupnotifications"
              component={SignUpNotifications}
            />
          </Route>
          <Route path="/change-password" component={ChangePassword} />
          <Route path="/profile" component={UserProfile} />
            <Route path="/vehicle" component={VehicleContainer} />
            <Route path="/vehicle/add" component={CreateVehicle} />
            <Route path="/vehicle/:vehicleId" component={DetailVehicle} />
        </Route>
        <Route path="/page-not-found" component={ErrorPage} />
        <Route path="*" component={ErrorPage} />
      </Router>
    </Provider>
  </Suspense>,
  document.getElementById('root')
);
