import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Translate, I18n } from 'react-redux-i18n';
import './styles/index.scss';
import Moment from 'react-moment';
import {
  FormGroup,
  Button,
  ButtonToolbar,
  Modal,
  Image,
} from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import CcCheckbox from '../../components/ccCheckbox/CcCheckbox';
import * as settingActions from '../../actions/settingActions';
import * as loadingBarActions from '../../actions/loadingBarActions';
import {
  CCLiteCommonFunc,
  getCompanyOfOperator,
  getSuppilerPermission,
  handleResultExport,
} from '../../utils/commonFunctions';
import { TableVehicle } from './tables/vehicle';
import TableActions from '../../components/table/tableAction/TableActions';
import StickyTable from '../../components/table/stickyTable/StickyTable';
import CCDropDown from '../../components/dropdown/ccDropDown';
import ExportComponent from '../../components/ExportComponent';
import { BsSearch } from 'react-icons/bs';
import { mappingVehicleTypeName } from '../../utils/vehicleType';
const CONFIRM_EXPORT_ID = 1;
class VehicleContainer extends Component {
  constructor() {
    super();
    this.state = {
      carTypes: [],
      dataList: null,
      showDialog: false,
      dialogData: null,
      showConfirm: false,
      sortType: -1,
      carTypeList: [],
      makeList: [],
      modelList: [],
      companyList: [],
      str: '',
      editable: false,
      valid: {},
      isSubmitted: false,
      tableHeight: 500,
      rowHeight: 50,
      footerData: {
        limit: 20,
        total: 0,
        page: 0,
      },
      showPercent: false,
      exportPercent: 0,
    };
    this.handlePaginationSelect = this.handlePaginationSelect.bind(this);
    this.handleResultExport = handleResultExport.bind(this);
    this.handleNumItemsPerPageChange =
      this.handleNumItemsPerPageChange.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.updateDataList = this.updateDataList.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.closeDialogForm = this.closeDialogForm.bind(this);
    this.confirmDeleteUser = this.confirmDeleteUser.bind(this);
    this.handleItemSelectedChange = this.handleItemSelectedChange.bind(this);
    this.initCommonData = this.initCommonData.bind(this);
    this.changeStatusMultipleSelected =
      this.changeStatusMultipleSelected.bind(this);
    this.deleteMultipleCarsSelected =
      this.deleteMultipleCarsSelected.bind(this);
    this.searchKeyPressHandle = this.searchKeyPressHandle.bind(this);
    this.searchStrChange = this.searchStrChange.bind(this);
    this.confirmDeleteMultiCars = this.confirmDeleteMultiCars.bind(this);
  }

  componentDidMount() {
    this.updateDataList();
    this.initCommonData();
    if (this.props.params.action) {
      switch (this.props.params.action) {
        case 'detail': {
          this.handleMenuClick('View', { _id: this.props.params.id });
          break;
        }
        case 'edit': {
          this.handleMenuClick('Edit', { _id: this.props.params.id });
          break;
        }
        default: {
          break;
        }
      }
    }
    this.setState({
      companies: _.cloneDeep([...this.props.commonData.companies, ...this.props.commonData.suppliers])
    })
  }

  initCommonData() {
    this.props.settingActions
      .getCarMakeList({ fleetId: this.props.auth.selectedFleet.fleetId })
      .then((data) => {
        if (data.ok && data.res) {
          this.state.makeList = data.res;
        }
      });
    this.props.settingActions
      .loadCarType(this.props.auth.selectedFleet.fleetId)
      .then((data) => {
        if (data.ok && data.res) {
          this.state.carTypeList = data.res;
          this.setState({ carTypeList: this.state.carTypeList });
        }
      });
    this.props.settingActions
      .fetchCompany({ fleetId: this.props.auth.selectedFleet.fleetId })
      .then((data) => {
        if (data.ok && data.res) {
          this.state.companyList = data.res.list;
        }
      });
  }

  updateDataList(activePage, limitParams) {
    const params = {
      limit: limitParams || this.state.footerData.limit,
      page:
        activePage || activePage === 0
          ? activePage
          : this.state.footerData.page,
      query: {
        fleetId: this.props.auth.selectedFleet.fleetId,
        carTypes: this.state.carTypes,
        txtSearch: this.state.str,
        companyId: this.state.companyId
      },
      sort: {
        createdDate: -1,
      },
    };
    // const permissionCompany = getCompanyOfOperator(
    //   this.props.auth,
    //   [...this.props.commonData.companies, ...this.props.commonData.suppliers]
    // );
    // if (permissionCompany?._id) {
    //   params.query.companyId = permissionCompany?._id;
    // }

    this.props.settingActions.getAllCars(params).then((data) => {
      if (data.ok && data.res) {
        const { page, total, limit } = data.res;
        this.setState({
          dataList: data.res,
          footerData: { page, total, limit },
        });
      }
    });
  }

  handleAddButtonClick() {
    this.props.router.push({
      pathname: '/vehicle/add',
    });
  }

  closeDialogForm() {
    this.props.router.push({
      pathname: CCLiteCommonFunc.getActiveMenuMainLink(
        this.props.menuHandle.selectedMainMenu
      ),
    });
    this.setState({
      showDialog: false,
      dialogData: null,
      showConfirm: false,
      multiDeleteConfirm: false,
      isSubmitted: false,
    });
  }

  handleMenuClick(key, dialogParams) {
    switch (key) {
      case 'View': {
        this.props.router.push({
          pathname: `/vehicle/${dialogParams._id}`,
          state: {
            edit: false,
          },
        });
        break;
      }
      case 'Edit': {
        this.props.router.push({
          pathname: `/vehicle/${dialogParams._id}`,
          state: {
            edit: true,
          },
        });
        break;
      }
      case 'Status': {
        this.props.settingActions
          .activeCars({
            fleetId: this.props.auth.selectedFleet.fleetId,
            vehicleId: dialogParams._id,
            isActive: !dialogParams.isReview ? true : !dialogParams.isActive,
          })
          .then((res) => {
            if (res.ok) {
              let list = _.cloneDeep(this.state.dataList.list);
              list = list.map((c) => {
                if (c._id == dialogParams._id) {
                  c.isActive = !c.isActive;
                  if (!c.isReview) {
                    c.isReview = true;
                  }
                }
                return c;
              });
              this.setState({ dataList: { ...this.state.dataList, list } });
            } else if (res.error) {
              this.context.notification(
                'error',
                I18n.t(`errors.${res.error.errorCode}`)
              );
            }
          });
        break;
      }
      case 'changeStatus': {
        this.setState({ editable: true });
        break;
      }
      case 'Delete': {
        this.setState({ showConfirm: true, dialogData: dialogParams });
        break;
      }
      default:
        break;
    }
  }

  handlePaginationSelect(eventKey) {
    this.updateDataList(eventKey);
  }

  handleNumItemsPerPageChange(e) {
    const numItemsPerPage = parseInt(e);
    this.updateDataList(0, numItemsPerPage);
  }

  confirmDeleteUser() {
    this.props.settingActions
      .deleteCars({
        fleetId: this.props.auth.selectedFleet.fleetId,
        vehicleId: this.state.dialogData._id,
      })
      .then((data) => {
        if (data.ok) {
          this.closeDialogForm();
          this.updateDataList();
          this.context.notification(
            'success',
            I18n.t('carSetting.Delete_car_success')
          );
        } else if (data.error) {
          this.context.notification(
            'error',
            I18n.t(`errors.${data.error.errorCode}`)
          );
        } else {
          this.context.notification(
            'error',
            I18n.t('carSetting.Delete_car_fail')
          );
        }
      });
  }

  handleItemSelectedChange(data, e) {
    const list = _.cloneDeep(this.state.dataList.list);
    if (data) {
      _.forEach(list, (car) => {
        if (car._id == data._id && !car.inUse) {
          car.selected = e.target.checked;
          return false;
        }
      });
    } else {
      _.forEach(list, (car) => {
        if (!car.inUse) {
          car.selected = e.target.checked;
        }
      });
    }
    this.setState({ dataList: { ...this.state.dataList, list } });
  }

  changeStatusMultipleSelected(status) {
    const ids = [];
    this.state.dataList.list.forEach((data) => {
      if (data.selected) {
        ids.push(data._id);
      }
    });
    this.props.settingActions
      .activeMultiCars({
        fleetId: this.props.auth.selectedFleet.fleetId,
        vehicleIds: ids,
        isActive: status,
      })
      .then((data) => {
        if (data.ok && data.res) {
          this.updateDataList();
          this.context.notification(
            'success',
            I18n.t('carSetting.Change_cars_status_success')
          );
        } else if (data.error) {
          this.context.notification(
            'error',
            I18n.t(`errors.${data.error.errorCode}`)
          );
        } else {
          this.context.notification(
            'error',
            I18n.t('carSetting.Change_cars_status_fail')
          );
        }
      });
  }

  confirmDeleteMultiCars() {
    this.setState({ multiDeleteConfirm: true });
  }

  deleteMultipleCarsSelected() {
    const ids = [];
    this.state.dataList.list.map((data) => {
      if (data.selected) {
        ids.push(data._id);
      }
    });
    this.props.settingActions
      .deleteMultiCars({
        fleetId: this.props.auth.selectedFleet.fleetId,
        vehicleIds: ids,
      })
      .then((data) => {
        if (data.ok && data.res) {
          this.updateDataList();
          this.setState({ multiDeleteConfirm: false });
          this.context.notification(
            'success',
            I18n.t('carSetting.Delete_cars_success')
          );
        } else if (data.error) {
          this.context.notification(
            'error',
            I18n.t(`errors.${data.error.errorCode}`)
          );
        } else {
          this.context.notification(
            'error',
            I18n.t('carSetting.Delete_cars_fail')
          );
        }
      });
  }

  carFilterCheckHandle = (list) => {
    this.setState({ carTypes: list }, () => this.updateDataList(0));
  };

  handleCompanyChange = companyId => {
    this.setState({ companyId }, () => this.updateDataList(0));
  };

  searchKeyPressHandle(e) {
    if (e.key == 'Enter') {
      this.updateDataList(0);
    }
  }

  searchStrChange(e) {
    const str = e.target.value;
    this.setState({ str });
  }

  getTableColumns = () => {
    const tableColumns = Object.assign([], TableVehicle);
    // custom data by column
    const { auth } = this.props;

    const removeColumns = [];
    if (auth?.user?.roles?.companyId) removeColumns.push('agent.name');
    if (auth?.user?.roles?.isSupplier) removeColumns.push('supplier.name');

    _.remove(tableColumns, (col) => removeColumns.indexOf(col.key) !== -1);

    _.forEach(tableColumns, (col) => {
      switch (col.key) {
        case 'palateNumber':
          col.customCell = (carMgmtData) => (
            <a
              className="clickable"
              onClick={() => {
                this.handleMenuClick('View', carMgmtData);
              }}
            >
              {carMgmtData.plateNumber}
            </a>
          );
          break;
        case 'hardwareMeter':
          col.customCell = (carMgmtData) => (
            <span>
              {carMgmtData.hwMetered ? (
                <i className="fa fa-check text-active" aria-hidden="true" />
              ) : (
                ''
              )}
            </span>
          );
          break;
        case 'year':
          col.customCell = (carMgmtData) => carMgmtData.year || '';
          break;
        case 'createdDate':
          col.customCell = (carMgmtData) => (
            <Moment
              tz={this.props.auth.selectedFleet.timezone}
              format="MMM/DD/YYYY"
              date={carMgmtData.createdDate}
            />
          );
          break;
        case 'type.name': 
          col.customCell = (carMgmtData) => mappingVehicleTypeName({vehicleTypeId: carMgmtData.type?.name, commonData: this.props.commonData});
          break;  
        case 'agent.name':
          col.customCell = (carMgmtData) => {
            let checkAgent = this.props.commonData?.companies
              ?.map((item) => item?.name)
              .includes(carMgmtData?.company?.name);
              let agentInclude = this.props.commonData?.suppliers?.filter(item => item?.name === carMgmtData?.company?.name);
            if (!checkAgent && agentInclude[0]?.agentId) {
              let newNameCompany = this.props.commonData?.companies?.filter(item => item?._id === agentInclude[0]?.agentId);
              return newNameCompany[0]?.name || '';
            }
            return checkAgent ? carMgmtData?.company?.name : '';
          };
          break;

        case 'supplier.name':
          col.customCell = (carMgmtData) => {
            let checkAgent = this.props.commonData?.companies
              ?.map((item) => item?.name)
              .includes(carMgmtData?.company?.name);
            return checkAgent ? '' : carMgmtData?.company?.name;
          };
          break;

        case 'regularStatus':
          col.customCell = (carMgmtData) => {
            let statusStr;
            let statusClass;
            if (carMgmtData && !carMgmtData.isReview) {
              statusStr = <Translate value="driver.In_Review" />;
              statusClass = 'Inreview';
            } else if (carMgmtData && carMgmtData.isActive) {
              statusStr = <Translate value="carSetting.Active" />;
              statusClass = 'Active';
            } else if (carMgmtData && !carMgmtData.isActive) {
              statusStr = <Translate value="carSetting.Inactive" />;
              statusClass = 'Inactive';
            }
            return (
              <div className={statusClass}>
                {!carMgmtData.inUse &&
                (!this.props.permissions || this.props.permissions.actions) ? (
                  <a
                    onClick={() => {
                      this.handleMenuClick('Status', carMgmtData);
                    }}
                    href="javascript:void(0)"
                  >
                    {statusStr}
                  </a>
                ) : (
                  statusStr
                )}
              </div>
            );
          };
          break;
        case 'actions':
          col.customCell = (carMgmtData, rowIndex) => {
            if (!this.props.permissions || this.props.permissions.actions) {
              const actionItems = [
                {
                  label: 'carSetting.Edit',
                  eventKey: 'Edit',
                },
                {
                  label: carMgmtData.isActive
                    ? 'carSetting.Deactivate'
                    : 'carSetting.Activate',
                  eventKey: 'Status',
                },
              ];

              if (!carMgmtData.isActive) {
                actionItems.push({
                  label: 'carSetting.Delete',
                  eventKey: 'Delete',
                });
              }

              if (this?.props?.auth?.user?.roles?.isSupplier) {
                let checkSupplierApproval =
                  this.props?.commonData?.companies?.find(
                    (item) =>
                      item._id === this.props?.auth?.user?.roles?.companyId
                  )?.supplierApproval;
                if (!checkSupplierApproval) {
                  _.filter(
                    actionItems,
                    (item) =>
                      item.label !== 'carSetting.Delete' ||
                      item.label !== 'carSetting.Activate'
                  );
                }
              }

              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={carMgmtData}
                  onSelect={(eventKey) => {
                    this.handleMenuClick(eventKey, carMgmtData);
                  }}
                  totalRow={this.state.dataList.list.length}
                  rowHeight={this.state.rowHeight}
                  menuItems={actionItems}
                  tableHeight={this.state.tableHeight}
                />
              );
            }
            return (
              <a
                onClick={(e) => {
                  this.handleMenuClick('View', carMgmtData);
                }}
                href="javascript:void(0)"
              >
                <Translate value="carSetting.View" />
              </a>
            );
          };
          break;
        default:
          break;
      }
    });

    const checkBoxCol = this.renderSelectBoxColumn();
    tableColumns.unshift(checkBoxCol);

    return tableColumns;
  };

  renderSelectBoxColumn = () => ({
    key: 'table-selectbox',
    label: '',
    width: 60,
    fixed: true,
    cellClass: 'cell-align-toolbar',
    headerClass: 'header-align-toolbar',
    customHeader: (columnKey) => {
      const canSelected = this.state.dataList
        ? _.filter(this.state.dataList.list, (item) => !item.inUse)
        : [];
      return (
        <CcCheckbox
          className="table-selectbox all-checkbox"
          checked={
            this.state.dataList &&
            this.state.dataList.list &&
            this.state.dataList.list.length
              ? this.state.dataList.list.filter((data) => data.selected)
                  .length === canSelected.length
              : false
          }
          onChange={(e) => {
            this.handleItemSelectedChange(null, e);
          }}
          disabled={
            !(!this.props.permissions || this.props.permissions.actions)
          }
        />
      );
    },
    customCell: (carMgmtData) => (
      <CcCheckbox
        disabled={
          carMgmtData.inUse || (this.props.permissions?.deactivatevehicle ? false :
          !(
            !this.props.permissions ||
            this.props.permissions.actions ||
            this.props.permissions.activatevehicle
          ))
        }
        checked={carMgmtData.selected}
        onChange={(e) => {
          this.handleItemSelectedChange(carMgmtData, e);
        }}
      />
    ),
  });

  getTableHeight = () => {
    const verticalPadding = 10;
    const toolbarMarginBottom = 10;
    const toolbarheight = this.toobarContainer
      ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight
      : 0;
    const parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    const outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
    let tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    if (window.innerHeight < 850 && tableHeight < 500) tableHeight = 550;
    this.state.tableHeight = tableHeight;
    return tableHeight;
  };

  handleConfirmButtonClick = (id) => {
    switch (id) {
      case CONFIRM_EXPORT_ID:
        break;
    }
    this.handleConfirmCloseClick();
  };

  handleExportToExcelClick = () => {
    this.doExport(true, null);
  };

  doExport = (isCheckExportExisted = false, email = '') => {
    const query = { fleetId: this.props.auth.selectedFleet.fleetId };
    query.locale = localStorage.getItem('language');
    if (this.state.str) {
      query.str = this.state.str;
    }
    const sort = { createdDate: this.state.sortType };
    if (this.state.carTypes) {
      query.carTypes = this.state.carTypes;
    }
    query.companyId = this.state.companyId
    query.timezone = this.props.auth.selectedFleet.timezone;
    this.setState({ showPercent: true, exportPercent: 0 });
    this.props.settingActions
      .exportVehilceToExcel({
        query,
        sort,
        isCheckExportExisted,
        email,
        total: _.get(this.state.footerData, 'total', 0),
      })
      .then((resp) => {
        return this.handleResultExport(resp, isCheckExportExisted);
      });
  };

  prepareParamExport = () => {
    const query = { fleetId: this.props.auth.selectedFleet.fleetId };
    query.locale = localStorage.getItem('language');
    if (this.state.str) {
      query.str = this.state.str;
    }
    const sort = { createdDate: this.state.sortType };
    if (this.state.carTypes) {
      query.carTypes = this.state.carTypes;
    }
    query.timezone = this.props.auth.selectedFleet.timezone;
    query.companyId = this.state.companyId;
    return {
      query,
      sort,
      total: _.get(this.state.footerData, 'total', 0),
    };
  };

  handleConfirmCloseClick = () => {
    this.setState({ confirm: null });
  };

  render() {
    const haveSelected = this.state.dataList
      ? this.state.dataList.list.filter((data) => data.selected).length > 0
      : false;
    const bodyData =
      this.state.dataList && this.state.dataList.list
        ? this.state.dataList.list
        : [];
    const { permissions = null, auth: { user } = {} } = this.props || {};
    const { actions = false, export: exportPermit = false } = permissions || {};
    const exportPermission = user.isAdmin ? true : exportPermit;
    return (
      <div className="content">
        {this.state.showConfirm && actions ? (
          <Modal onHide={this.closeDialogForm} show backdrop className="confirm">
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="carSetting.DELETE_CAR" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Translate value="carSetting.DELETE_CONFIRM" />
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn-cancel" onClick={this.closeDialogForm}>
                <Translate value="carSetting.No" />
              </Button>
              <Button
                className="btn-save ml-md"
                onClick={this.confirmDeleteUser}
              >
                <Translate value="carSetting.Yes" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ''
        )}
        {this.state.multiDeleteConfirm && actions ? (
          <Modal onHide={this.closeDialogForm} show backdrop className="confirm">
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="carSetting.DELETE_MULTI_CAR" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Translate value="carSetting.DELETE_MULTI_CAR_CONFIRM" />
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn-cancel" onClick={this.closeDialogForm}>
                <Translate value="carSetting.No" />
              </Button>
              <Button
                className="btn-save ml-md"
                onClick={this.deleteMultipleCarsSelected}
              >
                <Translate value="carSetting.Yes" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ''
        )}
        <ButtonToolbar
          className="text-center header-button-group"
          ref={(node) => (this.toobarContainer = node)}
        >
          <div className="group-left btn-group">
            <FormGroup className="search-format mb0">
              <input
                type="text"
                className="form-control search-form"
                value={this.state.str}
                onKeyPress={this.searchKeyPressHandle}
                onChange={this.searchStrChange}
                placeholder={I18n.t('General.search')}
              />
              <BsSearch className="search-icon" />
            </FormGroup>
            {!actions && this.props?.permissions?.add && (
              <Button
                className="btn-header text-add-header"
                onClick={this.handleAddButtonClick}
              >
                {' '}
                <Translate value="driver.Add" />
              </Button>
            )}
            {!actions && this.props?.permissions?.activatevehicle && (
              <>
                <Button
                  className="btn-header text-active-header"
                  disabled={!haveSelected}
                  onClick={() => {
                    this.changeStatusMultipleSelected(true);
                  }}
                >
                  {' '}
                  <Translate value="driver.Activate" />
                </Button>
              </>
            )}
            {!actions && this.props?.permissions?.deactivatevehicle && <Button
              className="btn-header text-deactive-header"
              disabled={!haveSelected}
              onClick={() => {
                this.changeStatusMultipleSelected(false);
              }}
            >
              <Translate value="driver.Deactivate" />
            </Button>}
            {actions && (
              <React.Fragment>
                <Button
                  className="btn-header text-add-header"
                  onClick={this.handleAddButtonClick}
                >
                  {' '}
                  <Translate value="driver.Add" />
                </Button>
                <Button
                  className="btn-header text-delete-header"
                  disabled={!haveSelected}
                  onClick={this.confirmDeleteMultiCars}
                >
                  {' '}
                  <Translate value="driver.Delete" />
                </Button>
                <Button
                  className="btn-header text-active-header"
                  disabled={!haveSelected}
                  onClick={() => {
                    this.changeStatusMultipleSelected(true);
                  }}
                >
                  {' '}
                  <Translate value="driver.Activate" />
                </Button>
                <Button
                  className="btn-header text-deactive-header"
                  disabled={!haveSelected}
                  onClick={() => {
                    this.changeStatusMultipleSelected(false);
                  }}
                >
                  <Translate value="driver.Deactivate" />
                </Button>
              </React.Fragment>
            )}
          </div>
          <div className="group-right">
            <CCDropDown
              id="company-dropdown"
              items={this.state.companies}
              title={I18n.t('report.query.company')}
              selectedItems={this.state.companyId || []}
              valueKey="_id"
              labelKey="name"
              onSelect={this.handleCompanyChange}
              noTranslateLabel
              searchable
              enableClearAll
            />
            <CCDropDown
              id="cartype-dropdown"
              title="carSetting.Car_type_filter"
              items={this.state.carTypeList}
              valueKey="vehicleType"
              labelKey="appDisplayName"
              noTranslateLabel
              onSelect={this.carFilterCheckHandle}
              selectedItems={this.state.carTypes}
              className="ccDropdown-header-group"
              multiSelect
              pullRight
              enableAllItemSelect
              onlyShowAllText
            />
            {permissions && exportPermission && this.props.auth?.selectedFleet?.generalSetting?.ableToDownloadAccount && (
              <ExportComponent
                prepareParamExport={this.prepareParamExport}
                urlExport={this.props.settingActions.exportVehilceToExcel}
                user={user}
              />
            )}
          </div>
        </ButtonToolbar>
        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={this.state.footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={this.state.rowHeight}
            getTableHeight={this.getTableHeight}
          />
        </div>
      </div>
    );
  }
}

VehicleContainer.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission,
    menuHandle: state.menuHandle,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(VehicleContainer);
