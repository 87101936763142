export const mappingVehicleTypeDispatchName = (params) => {
  const { vehicleType, commonData } = params;

  const dispatch = vehicleType.dispatch;
  const vehicleTypes = commonData.carType;

  return dispatch
    .map(
      (item) =>
        vehicleTypes.find((type) => type.vehicleType === item)
          ?.appDisplayName || item
    )
    .join(', ');
};

export const mappingVehicleTypeName = (params) => {
  const { vehicleTypeId, commonData } = params;
  const vehicleTypes = commonData.carType;
  return (
    vehicleTypes.find((type) => type.vehicleType === vehicleTypeId)
      ?.appDisplayName || vehicleTypeId
  );
};
